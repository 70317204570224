import React from "react"
//import { Link } from "gatsby"

// import { rhythm, scale } from "../utils/typography"
// import { createMuiTheme } from "@material-ui/core";
// import AppBar from '@material-ui/core/AppBar';
import Navbar from './navbar'
import Box from '@material-ui/core/Box';
import { ThemeProvider } from '@material-ui/styles';
import mutedTheme from '../themes/muted-theme.js';
// import rougeTheme from '../themes/rouge-theme.js';
// import greenTheme from '../themes/green-theme.js';
// import coffeeTheme from '../themes/coffee-theme.js';
// import typeTheme from '../themes/type-theme.js';
import Link from '@material-ui/core/Link';
// import Toolbar from '@material-ui/core/Toolbar';
// import Container from '@material-ui/core/Container';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fab)

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    const socialMediaIcons = [
    {
      url: 'https://github.com/vidella-lee',
      className: 'fa-github-square',
    },
    {
      url: 'https://twitter.com/vidella_lee',
      className: 'fa-twitter',
    },
    ];

    let header = ( <Navbar />)

  
    return (
      <ThemeProvider theme={mutedTheme}>
      <div
        //style={{
          // width: '100vw',
          // maxWidth: '100%',
          // height: '100vh',
          // marginLeft: `auto`,
          // marginRight: `auto`,
          // maxWidth: rhythm(24),
          // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        //}}
      >
        <header>{header}</header>
       
        <main>{children}</main>
        
        <Box
          className='footer'
          color='primary.contrastText'
          bgcolor='primary.main'
        >
    
          <Box>
            {new Date().getFullYear()}
          </Box>
          <Box>
            
            
             <Link
              href='https://github.com/vidella-lee'
              target="_blank"
              > 
                <FontAwesomeIcon
                 icon={['fab', 'github']}
                 className='icon-link-footer'
                />
             </Link>  
          </Box>
         {/*<Box>
          <img
           id='greencheck-image'
           src="https://api.thegreenwebfoundation.org/greencheckimage/www.lizvnelson.com?nocache=true" alt="This website is hosted Green - checked by thegreenwebfoundation.org" />
          </Box>*/}
        </Box>
      </div>
      </ThemeProvider>
    )
  }
}

export default Layout
