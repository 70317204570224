import { Link } from 'gatsby'
// import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';

//import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: { 
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Navbar() {
  const classes = useStyles();
  return (
    <div className={classes.root}>

    <Box>
      <AppBar
       position='static'
       //bgcolor='primary.dark'
       //minHeight={100}
       style={{
          boxShadow: "none",
       }}
       color='primary'
       >
        <Toolbar> 
          <Typography 
           variant="body1"
           className={classes.title}
          >
            <Link 
             to="/"
             // activeStyle={{ color: "#516658" }}
             className='navbar-link'
             color='secondary'
             underline='none'
             //underlineNone={true}
             >
               LVN
            </Link>   
          </Typography>
          <Typography 
           variant="body1"
           >
           <Link 
             to="/about-liz/"
             activeStyle={{ color: "var(--secondaryMain)" }}
             className='navbar-link'
             color='secondary'
             underline='none'
             >
              about liz.
            </Link>
        
     
          	<Link 
          	 to="/projects/"
          	 className='navbar-link'
          	 color='secondary'
             underline='none'
             activeStyle={{ color: "var(--secondaryMain)" }}
            >
          		projects.
          	</Link>
          
     
            <Link 
             to="/blog/"
             className='navbar-link'
             color='secondary'
             underline='none'
             activeStyle={{ color: "var(--secondaryMain)" }}
             >
              blog.
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      </Box>

    </div>
  );
}