import { createMuiTheme } from '@material-ui/core/styles';

export const mutedTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#1F1718',  //plum black
      light: '#E7E1E1', //grey beige
      dark: '#6B454A', //rouge purple

      contrastText: '#E7E1E1', //grey beige
    },
    secondary: {
      main:'#8A6A6D', //mauve
      extra: '#963640',
      light: '#FFFFFF', //white
      extra2: "#748D65",
      //light: '#9EACB5', //light stony blue
      dark: '#E0B05E', //yellow gold
      green: '#91AB8E', //green
      // dark: '#232729',
      //dark: '3D292B', //plum
      //contrastText: '#ffcc00',
    },
      alternateTextColor: '#1F1718', //dark roast

  },
  status: {
    danger: { 500:'#225b92' },
  },
   typography: {
     fontFamily: [
      'Abel',
      'Lora',
     ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Lora'],
      },
      MuiExpansionPanel: {
      root: {
          boxShadow: 'none',
        }
      },
      MuiBox:{
        root: {
          boxShadow: 'none',
        }
      },
      // '@global' : {
      //   '.MuiExpansionPanel-root': {
      //       fontSize: '72px',
      //       boxShadow: 'none',
      //   }
      // }
    },

      MuiAppBar: {
        colorPrimary: {
          background: '#1F1718', //plum black
        }
      },
    },
});

mutedTheme.shadows = [];

export default mutedTheme;